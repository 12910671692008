import React, {useState} from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "react-slick";
import {featured_image} from '../assets/global';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';
import {whr_orange, whr_blue} from '../assets/colours';

const HomePage = (data) => {
    
    const [menuOpen, setMenuOpen] = useState(false);
    function toggleMenu(){
        setMenuOpen(!menuOpen)
    }

    const settings = {
        autoplay: true,
        autoplaySpeed: 6500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        fade:true,
        lazyLoad: true,
    }

    return (
        <Layout title = {data.data.wpPage.seo.title} menuOpen = {menuOpen} toggleMenu = {toggleMenu} fullWidthMenu = {true}>
            <Seo description = {data.data.wpPage.seo.metaDesc} title = {data.data.wpPage.seo.title} />
            <Overlay>
                <h1>
                    {data.data.wpPage.pageTemplateHome.headline}
                </h1>
                <div className = "button-wrapper">
                    <button onClick = {toggleMenu} className = "services">Our Services</button>
                    <Link className = "contact" to = "/contact" >Contact Us</Link>
                </div>
            </Overlay>
            <SliderWrapper>
                <Slider {...settings}>
                    {
                        data.data.wpPage.pageTemplateHome.featuredImages.map((featuredImage, index) => {
                            var slideImage = {
                                fluid: featuredImage?.localFile?.childImageSharp?.fluid,
                                alt: featuredImage?.alt || ``,
                            }
                            return(
                                <Image
                                key = {index}
                                fluid={slideImage.fluid}
                                alt={slideImage.alt}
                                style={{height: '100%'}}
                                />
                            )
                        })
                    }
                </Slider>
            </SliderWrapper>
        </Layout>
    )
}

export default HomePage

const Overlay = styled.div`
    height:100%;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    z-index:98;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    align-content:center;
    background:rgba(0,0,0,0.25);

    @media (min-width:0px){
        padding:0px 24px;
        h1{
            font-size:3em;
        }
        
    }
    @media (min-width:768px){
        padding:0px 48px;
        h1{
            font-size:5.5em;
            max-width:50%;
        }
    }

    h1{
        font-family:"Bebas Neue", sans-serif;
        color:white;
        text-align:center;
        letter-spacing:1px;
    }

    .button-wrapper{

        @media (min-width:0px){
            margin-top:48px;
        }
        @media (min-width:768px){
            margin-top:72px;
        }
        
        width:100%;
        display:flex;
        flex-wrap:wrap;
        justify-content:center;

        .services, .contact{
            margin-bottom:24px;
            opacity:1;
            transition:0.7s;
            
            border:0; 
            border-radius:2px;
            font-family:"Bebas Neue", sans-serif;
            color:white;
            font-size:32px;
            text-decoration:none;
            cursor:pointer;
            padding:24px 48px;
            @media (max-width:767px){
                width:75%;
                font-size:24px;
                padding:18px 36px;
            }
            
            text-align:center;
        }
    
        .services{
            background:${whr_orange};
            @media (min-width:768px){
                margin-right:24px;
            }

            &:hover{
                opacity:0.8;
                transition:0.7s;
            }
            
        }
    
        .contact{
            background:${whr_blue};

            &:hover{
                opacity:0.8;
                transition:0.7s;
            }
        }
    }

 
`

const SliderWrapper = styled.div`
    ${featured_image};
    width:100%!important;
    left:0!important;
`

export const pageQuery = graphql`
{
    wpPage(isFrontPage: {eq: true}) {
        seo {
            metaDesc
            title
        }
        id
        title
        pageTemplateHome {
          headline
          logo {
            localFile {
            childImageSharp {
                fluid(maxWidth: 1800, quality: 100){
                    src
                }
              }
            }
          }
          featuredImages {
            localFile {
            childImageSharp {
                fluid(maxWidth: 1800, quality: 100){
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
    }
} 
`
